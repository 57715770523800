import PlaceSearch from "./../PlaceSearch";
import { ui } from "./utils/ui";

const Home = () => {
    if (!ui?.module || !ui?.form) return;

    PlaceSearch(
        ui?.controls?.geolocation,
        ui?.fields?.search,
        ui?.fields?.latitude,
        ui?.fields?.longitude,
        ui?.fields?.address,
        ui?.fields?.postalCode,
        ui?.fields?.city
    );
};

export default Home;
